import logo from './logo.svg';
import './App.css';

function App() {
  return (
      <body>
        <div className="App">
            <h1>This domain is for sale!</h1>
            <h2>Please email offer@joebam.com for details.</h2>
            <button id={'b1'}>Click Here!</button>
        </div>
      </body>
  );
}

export default App;
